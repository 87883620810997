$breakpoint-name: "sm";
$breakpoint-name: "sm" !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;

            &.in {
                min-width: 50%;
                left: 0;
            }
        }
    }
}

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
    color: $dusk-blue !important;
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    .navbar-nav .nav-link {
        font-size: 14px;
    }

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }

        .navbar-nav .nav-link {
            padding: 0.8rem;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                padding-top: 0.9rem;
            }

            @include media-breakpoint-down(md) {
                padding-left: 0.3rem;

                &.md-pad {
                    padding-left: 0.8rem;
                }
            }
        }
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: transparent !important;
    color: $dusk-blue !important;
    font-weight: $font-weight-bold;
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $white;
    }
}

.main-menu.menu-toggleable-left {
    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        padding: 15px;
        background-color: $ice-blue;
        border-bottom: 1px solid $pale-lilac;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    .menu-group {
        flex: 0 0 100%;
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {
        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em; /* 20/16 */
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 1px solid $dusk-blue;
            }

            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-toggle {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                padding-left: 1rem;
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 90%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-menu > .dropdown > .dropdown-menu {
        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        padding: 0 0 0 1em;

        .dropdown-link {
            display: block;
            padding: 0.425em 5em 0.425em 0;
        }

        + .dropdown-item {
            border-top: 1px solid $close-menu-bg;
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;
            }
        }

        &:hover {
            background: transparent !important;
        }

        &:focus {
            background: transparent !important;
        }
    }
}

.nav-item.dropdown {
    .nav-link.dropdown-toggle {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            &::after {
                margin-top: 0 !important;
            }
        }

        @include media-breakpoint-up(md) {
            &::after {
                padding-bottom: 2px;
            }
        }
    }
}

.navbar-header.brand {
    @include media-breakpoint-up(md) {
        padding-left: 12px !important;
    }

    .logo-sizing {
        height: 50px;
        width: 180px;
    }
}

.dropdown-link.dropdown-toggle {
    &::after {
        vertical-align: 2px;
    }
}

.mobile-cat-height {
    @include media-breakpoint-down(sm) {
        height: 22px;
        font-size: 1rem;
    }
}

.mobile-nav-link-pad {
    @include media-breakpoint-down(md) {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &.mob-top {
        @include media-breakpoint-only(md) {
            padding-top: 23px !important;
            padding-bottom: 9px;
        }
    }
}

.dropdown-item.top-category {
    .dropdown-link {
        @include media-breakpoint-down(md) {
            padding-top: 12px;
        }
    }

    .sub-drop-pad {
        padding-top: 12px !important;
    }
}

.sub-drop-pad {
    padding-top: 12px !important;

    &::after {
        margin-top: 5px !important;
    }
}

.dropdown-link {
    @include media-breakpoint-down(md) {
        padding-top: 12px !important;
    }

    font-size: 12px !important;
}

.nav-link {
    @include media-breakpoint-down(md) {
        padding-top: 16px !important;
    }
}

.dropdown-menu.show {
    @include media-breakpoint-up(md) {
        top: 75% !important;
    }
}

.dropdown-menu {
    @include media-breakpoint-up(md) {
        ul {
            left: 75% !important;
            top: 75% !important;
        }
    }
}

.nav.navbar-nav {
    @include media-breakpoint-down(sm) {
        padding-bottom: 20px;
    }
}

.contact-pad {
    padding-right: 1.8rem !important;

    @include media-breakpoint-down(md) {
        padding-right: 0.8rem !important;
    }
}

.m-w-100 {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    @include media-breakpoint-only(md) {
        margin-top: -7px;
    }
}

.nav-bar-mar {
    margin-left: 15px;
    @include media-breakpoint-up(xl) {
        margin-left: 31px;
    }
}

.user-message,
.fa.fa-sign-in {
    color: #1f3d76;

    &.um-logged-in {
        padding-bottom: 1px;
    }
}

.nav-link.dropdown-toggle {
    &::after {
        color: #1f3d76;
    }
}

.right-nav {
    @include media-breakpoint-up(xl) {
        padding-right: 10px;
    }

    @include media-breakpoint-only(md) {
        padding-left: 25px;
    }
}
