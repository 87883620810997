@import "functions";
@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts";

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/";

//--------------------------------
//  COLORS
//--------------------------------

// Theme colors documentation: https://getbootstrap.com/docs/4.1/getting-started/theming/#theme-colors

// Color Palette

/* Variable names from http://chir.ag/projects/name-that-color */

$white: #fff;
$ice-blue: #f9faff;
$green: #4fd75c;
$dusk-blue: #1f3d76;
$faded-yellow:  #f5fe81;
$green: #008827 !default;
$red: #c00 !default;
$periwinkle-blue: #7997fc;
$pale-lilac: #f3f4ff;
$trans: transparent;
$dark-gray: #485766;
$light-blue: #3c78ba;
$dark-blue: #1e419a;
$black: #081932;
$black-20 : #000;

// Gray Colors - Bootstrap overrides
$gray-100: #f8f8f8;
$gray-200: #f1f1f1;
$gray-300: #e9e9e9;
$gray-400: #dfe3e6;
$gray-500: #a6a6a6;
$gray-600: #888;
$gray-700: #555;
$gray-800: #3c3c3c;
$gray-900: #272727;

//--------------------------------
//  OTHER THEME COLORS
//--------------------------------

$success: $green;
$danger: $red;
$primary: $light-blue;
$secondary: $light-blue;
$slightly-darker-gray: $gray-300;
$darker-gray: $gray-500;
$state-danger-text: $white;
$hover-bg: rgba(255, 255, 255, 0.15);
$video-nav-bg: rgba(85, 85, 85, 0.7);

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;

//--------------------------------
//  BACKGROUNDS
//--------------------------------

// BG utility class documentation: https://getbootstrap.com/docs/4.0/utilities/colors/#background-color

// Gradients
// Gradients utility class documentation: https://getbootstrap.com/docs/4.0/utilities/colors/#background-gradient

$enable-gradients: false; // bootstrap override -> must be set to true for gradients to be visible on the site

//--------------------------------
//  TYPOGRAPHY
//--------------------------------

$font-family-nominee: 'Nominee', Arial, Helvetica, sans-serif;
$font-family-nominee-condensed: 'Nominee Condensed', Arial, Helvetica, sans-serif;
$font-family-founders-grotesk: 'Founders Grotesk', Arial, Helvetica, sans-serif;
$font-family-sharp-grotesk: 'Sharp Grotesk', Arial, Helvetica, sans-serif;
$font-family-base: $font-family-founders-grotesk;
$font-family-special: $font-family-sharp-grotesk;
$font-size-base: 1rem; // 16px - sets base font size applied to html element
$font-size-sm: $font-size-base; // 16px
$font-size-xs: 0.8rem;
$font-size-body: 1.2rem; // 18px - font size applied to body element
$font-weight-regular: 500;
$font-weight-medium: 500;
$font-weight-light: 300;
$font-weight-bold: 600;
$font-weight-bolder: 800;

// Fonts

$font-family-second: $font-family-special;

// Displays
$display1-size: 5rem; //bootstrap override
$display1-mobile-size: 2.5rem;
$display2-size: 2.5rem; //bootstrap override
$display2-mobile-size: 2rem;

// Headings

$headings-font-family: $font-family-second; //bootstrap override
$h1-font-size: 1.625rem; //bootstrap override
$h2-font-size: 1.5rem; //bootstrap override
$h3-font-size: 1.25rem; //bootstrap override
$h4-font-size: 1rem; //bootstrap override

// Weights

$checkout-font-weight: 600;

// Labels

$label: $font-size-base;

//--------------------------------
//  LINKS
//--------------------------------
$link-color: $light-blue; //bootstrap override
$link-decoration: none;
$link-hover-color: $dark-gray; //bootstrap override
$link-hover-decoration: underline; //bootstrap override
$link-decoration-alt: none;

//--------------------------------
//  BUTTONS
//--------------------------------

$btn-icon-size: 12px;
$btn-icon-spacing: 8px;
$btn-link-color: $white; //bootstrap override
$btn-line-height: rem(20px);
$btn-line-height-sm: rem(20px);
$btn-line-height-lg: rem(20px);
$btn-disabled-opacity: 1;
$btn-disabled-main-color: $gray-500;
$btn-disabled-main-bg: $gray-300;
$btn-disabled-main-border-color: $gray-300;
$btn-disabled-alt-color: $gray-500;
$btn-disabled-alt-bg: $gray-300;
$btn-disabled-alt-border-color: $gray-300;
$btn-font-size: $font-size-base; //bootstrap override
$btn-font-weight: $font-weight-regular;
$btn-padding-y: rem(13px);
$btn-padding-x: rem(36px);
$btn-padding-y-sm: rem(10px);
$btn-padding-x-sm: rem(11px);
$font-size-lg: $font-size-base * 1.25; //bootstrap override
$btn-padding-y-lg: 0.9rem; //bootstrap override
$btn-border-width: 2px;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

//--------------------------------
// List group
//--------------------------------

$list-group-border-color: $dark-gray;
$list-group-border-radius: 4px;

//--------------------------------
//  FORMS
//--------------------------------

// all bootstrap overrides
$form-group-margin-bottom: 1.5rem;
$input-border-color: $gray-600;
$input-color: $gray-700;
$input-border-radius: 0;
$input-box-shadow: none;
$input-bg: $white;
$input-placeholder-color: $gray-700;
$input-disabled-bg: $white;
$custom-select-bg: $white;
$custom-select-border-radius: 0;
$custom-select-focus-box-shadow: none;
$input-padding-x: rem(15px);
$input-padding-y: rem(13px);
$custom-select-padding-y: 0.75rem;
$custom-select-bg-size: 22px 22px;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-bg: $white;
$text-muted: $gray-400;

//--------------------------------
//  BUTTONS & FORMS
//--------------------------------

$input-btn-padding-y: rem(13px); //bootstrap override
$input-btn-padding-x: rem(36px); //bootstrap override
$btn-box-shadow: 0 0 0 transparent; //bootstrap override
$btn-active-box-shadow: 0 0 0 transparent; //bootstrap override

// Grid columns

// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px; // How do we want to update this to be reflective of the 8px spacing for mobile?

//--------------------------------
//  TABLES
//--------------------------------

$table-th-border-color: $gray-400;
$table-border-color: $gray-400;

//--------------------------------
//  QUICKVIEW COMPONENT
//--------------------------------

$quickview-font-size1: $font-size-base * 0.625;
$quickview-font-size2: $font-size-base * 1.5;
$quickview-font-size3: $font-size-base * 1.6;
$quickview-font-size4: $font-size-base * 1.875;
$quickview-font-size5: $font-size-base * 2;

//--------------------------------
//  TRANSITION
//--------------------------------

$transition-ease: all 0.2s ease;
$transition-ease-in-out: all 0.2s ease-in-out;
$transition-background: background-color 0.5s;

//--------------------------------
//  MOTION
//--------------------------------

$ease-in-cubic-bezier: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
$card-hover-scale: scale(1.25);
$button-hover-scaley: scaleY(1.5);

@keyframes easeInCubicMotion {
    to {
        transform: translate(150px);
    }
}

@keyframes easeOutCubicMotion {
    to {
        transform: translate(150px);
    }
}

$ease-in-cubic: easeInCubicMotion 200ms $ease-in-cubic-bezier;
$ease-out-cubic: easeOutCubicMotion 200ms $ease-out-cubic-bezier;

