@font-face {
    font-family: 'Founders Grotesk';
    src: url("../fonts/FoundersGrotesk-Regular.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: 'Sharp Grotesk';
    src: url("../fonts/SharpGrotesk-Book20.ttf") format("truetype");
}

