@import "~base/components/header";
@import "variables";

header {
    background: #f9faff;
    font-family: $font-family-special;
}

.main-menu {
    background: transparent !important;

    @include media-breakpoint-up(xl) {
        padding-top: 10px;
    }

    .navbar .close-menu button,
    .navbar .close-button button {
        color: $dusk-blue;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 0;
    }
}

.btn {
    margin-right: 1em;
    margin-bottom: 1em;
}

.input1,
.button1 {
    border-radius: 40px;
    font-size: 1.125rem;
    color: $dusk-blue;
    background: $faded-yellow;
    text-transform: none;
    font-weight: bold;
    padding: 12px 20px 8px;
    box-shadow: none;
    border: 0;
    outline: 0;

    @include media-breakpoint-down(sm) {
        width: 15em;
        margin-left: 3%;
    }
}

.input1 {
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.navbar-header {
    @include media-breakpoint-down(lg) {
        height: 3.375em !important;
    }

    @include media-breakpoint-down(sm) {
        padding-left: 0;
    }

    .brand {
        left: 0;
        right: 0;
        margin: auto;
        position: relative;
        text-align: left;
        width: 17.125em;

        img {
            width: 90%;
        }

        @include media-breakpoint-down(sm) {
            width: 10em; /* 64/16 */
            padding-top: 1em;
        }

        @include media-breakpoint-only(md) {
            width: 13em;
        }

        @include media-breakpoint-down(md) {
            padding-top: 8px !important;
        }
    }

    .user {
        .popover {
            padding: 0;
            font-family: $font-family-nominee;
            font-weight: $font-weight-regular;

            li {
                padding: 0 0 0 1em;
                border-top: 1px solid #eee;
                color: $dark-gray;
                width: 100%;

                a {
                    padding: 0.425em 5em 0.425em 0;
                    display: block;
                    margin-bottom: 0;
                }
            }
        }
    }

    .search {
        .site-search {
            .form-control {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    button.back-btn {
        display: inline-block;
        margin: 1.3em 0 0 0.5em;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;

        &:focus {
            outline: 0;
        }

        span.caret-left {
            border-top: 0.3em solid transparent;
            border-bottom: 0.3em solid transparent;
            border-right: 0.3em solid;
            width: 0;
            height: 0;
            display: inline-block;
            margin-bottom: 0.125em;
        }
    }

    .navbar-toggler {
        &:focus {
            outline: 0;
        }
    }
}

.nav-container {
    max-width: 100vw !important;
}
