@import "collapsibleItem";
@import "toastMessage";

footer {
    background-color: $dusk-blue;
    padding-top: 3em; /* 20/16 */
    padding-bottom: 3em;

    @include media-breakpoint-down(sm) {
        padding-left: 1em;
    }

    h2 {
        font-size: $font-size-base;
        margin-bottom: 0;
        line-height: 2.5em; /* 40/16 */
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    .social {
        h2 {
            margin-top: 0;
        }

        @include clearfix;
    }

    .copyright,
    .social {
        margin-top: 0;
    }

    .footer-container .footer-item.collapsible-xs button {
        font-family: 'Dosis', sans-serif;
        padding: 0;
    }

    .social-links {

        @include clearfix;

        @include media-breakpoint-down(xs) {
            width: 80%;
        }

        float: left;

        li {
            float: left;
            margin: 0.313em;

            @include media-breakpoint-down(xs) {
                width: 20%;
                text-align: center;
            }
        }

        a {
            font-size: 2.25em;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .store {

        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $dark-gray;

            .content {
                display: none;
            }

            h2 {
                @include clearfix;

                &::after {
                    font-family: "FontAwesome";
                    float: right;
                    content: "\f041";
                }
            }
        }
    }

    .content {
        font-size: 0.875em;

        li {
            height: 2.25rem;
            font-family: $font-family-base;
            font-size: 1.25rem;

            a {
                color: $white;

                &:hover {
                    color: $periwinkle-blue;
                    text-decoration: none;
                }
            }
        }
    }

    .copyright,
    .postscript {
        font-size: 0.8125em;
    }

    .copyright {
        margin-bottom: 0.625em;
    }

    .copyright-notice {
        li {
            display: inline-block;
            padding-right: 1em;
            padding-left: 1em;
            font-size: 0.75em;

            @include media-breakpoint-down(xs) {
                display: block;
                padding: 0.5em 0;
            }
        }
    }

    .back-to-top {
        margin: 0.1em 0.313em;
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;

        i {
            &.fa-arrow-up {
                color: rgba(0, 0, 0, 0.7);
            }

            &.fa-circle {
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.footer-item {
    .title {
        text-transform: uppercase;
        margin-bottom: 0.5em;
        font-weight: $font-weight-bold;
        display: block;
    }

    .home-email-signup {
        .form-control {
            flex: 0 0 40%;
            background: $ice-blue;
            font-family: $font-family-base;
            margin-right: 1em;
            outline: 0;
            border: 0;

            @include media-breakpoint-down(sm) {
                flex: 0 0 55%;
            }
        }

        .input1,
        .button1 {
            border-radius: 40px;
            margin: 0;
            font-family: $font-family-base;
            padding-left: 2em;
            padding-right: 2em;
            z-index: 0;

            &:hover {
                background: $periwinkle-blue;
                color: $ice-blue;
            }

            @include media-breakpoint-down(sm) {
                width: auto;
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }

    .home-email-signup {
        background: transparent;

        p {
            color: $white;
            font-size: 1.125rem;
            font-family: $font-family-base;
        }
    }
}

.footer-logo {
    img {
        width: 70%;

        @include media-breakpoint-down(xs) {
            width: 50%;
        }
    }

    p {
        color: $white;
        font-size: 1rem;
        font-family: $font-family-base;
        margin-bottom: 0;
        padding-left: 1em;
        padding-right: 5.5em;

        @include media-breakpoint-down(sm) {
            padding-right: auto;
        }
    }
}

.footer-social {
    a {
        text-decoration: none;
    }

    i {
        padding-right: 0.5em;
        font-size: 1.5em;
        color: $white;

        &.fa-instagram {
            color: $white;

            &:hover {
                color: $periwinkle-blue;
            }
        }
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}

#footercontent.mobile-pdp-margin {
    @include media-breakpoint-down(sm) {
        margin-bottom: 237px;
    }
}
