@import "~base/components/productTiles";

.product-tile {
    min-height: 0;

    .image-container {
        border-radius: 20px;

        .wishlist-heart {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
        }
    }

    .pdp-link {
        .link {
            font-size: 20px !important;
        }
    }

    .sq-ft-span {
        margin-top: 4px;
    }
}
