@import "~base/components/headerSearch";

.site-search {
    height: 45px;

    @include media-breakpoint-up(md) {
        width: 10em;
        margin-right: 20px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    input {
        font-family: "Founders Grotesk";
        font-style: normal;
        font-weight: 400;
        line-height: 0%;
        padding-right: 0;
        padding-left: 2em !important;
        padding-top: 14px;
        font-size: 17px !important;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #1f3d76;
            opacity: 1; /* Firefox */
        }

        @include media-breakpoint-down(sm) {
            font-size: 19px !important;
        }
    }

    .header-search-icon {
        top: 13px;
        left: 12px;
        width: 8%;

        @include media-breakpoint-down(xs) {
            left: 22px;
        }
    }

    .mob-search-width {
        width: 158px;

        @include media-breakpoint-down(sm) {
            width: 204px;
        }
    }
}
